import React from "react";
import Home from "../../assets/Home.svg";
import RightArrow from "../../assets/RightArrow.svg";
import Help from "../../assets/Help.svg";
import About from "../../assets/About.svg";
import Car from "../../assets/Car.svg";
import Motorbike from "../../assets/Motorbike.svg";
import Community from "../../assets/Community.svg";
import Phone from "../../assets/Phone.svg";
import SmallCar from "../../assets/SmallCar";
import Search from "../../assets/Search.svg";
import News1 from "../../assets/News1.svg";
import News2 from "../../assets/News2.svg";
import News4 from "../../assets/News4.svg";
import News5 from "../../assets/News5.svg";
import SmallPhone from "../../assets/SmallPhone";
import SmallBike from "../../assets/SmallBike";
import theme from "../../theme";
import Qaulity from "../../assets/Qaulity";
import Battery from "../../assets/Battery";
import Speed from "../../assets/Speed";
import Eye from "../../assets/Eye";
import AppGrid from "../../assets/AppGrid";
import UserPhoneMotorola from "../../assets/UserPhoneMotorola.svg";
import UserPhoneRedmi from "../../assets/UserPhoneRedmi.svg";
import UserPhonePoco from "../../assets/UserPhonePoco.svg";

export const displayFeatures = [
  { id: 1, name: "Resolution", checked: false },
  { id: 2, name: "Features", checked: false },
];

export const connectivityFeatures = [
  { id: 1, name: "USB", checked: false },
  { id: 2, name: "WIFI", checked: false },
  { id: 3, name: "NFC", checked: false },
  { id: 4, name: "Bluetooth", checked: false },
];
export const cameraFeatures = [
  { id: 1, name: "Primary Camera", checked: false },
  { id: 2, name: "Front Camera", checked: false },
  { id: 3, name: "Zoom", checked: false },
  { id: 4, name: "Flash", checked: false },
  { id: 5, name: "Video Recording", checked: false },
];
export const generalFeatures = [
  { id: 1, name: "SIM Type", checked: false },
  { id: 2, name: "SIM Size", checked: false },
  { id: 3, name: "Network Type", checked: false },
  { id: 4, name: "Removable Battery", checked: false },
  { id: 5, name: "Sensors", checked: false },
];
export const performance = [
  { id: 1, name: "Processor", checked: false },
  { id: 2, name: "Graphics", checked: false },
  { id: 3, name: "Operating System", checked: false },
];
export const reviews = [
  { id: 1, name: "Top Reviews", checked: false },
  { id: 2, name: "1 Star Reviews", checked: false },
  { id: 3, name: "2 Star Reviews", checked: false },
];

export const storage = [
  { id: 1, name: "Internal Memory", checked: false },
  { id: 2, name: "Extended Memory", checked: false },
];
export const categorytext = [
  "1) Select the type of product you are planning to buy",
  "2) What’s your budget?",
  "3) Select 3 attributes you prefer the most",
];

export const buyingTypes = [
  {
    id: 1,
    name: "Phones",
    border: "none",
    background: theme.colors.grays[100],
    icon: <SmallPhone />,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 2,
    name: "Cars",
    border: "none",
    background: theme.colors.grays[100],
    icon: SmallCar,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 3,
    name: "Motorbike",
    border: "none",
    background: theme.colors.grays[100],
    icon: SmallBike,
    text: theme.colors.grays[900],
    fill: "",
  },
];

export const attributeTypes = [
  {
    id: 1,
    name: "Performance",
    border: theme.colors.primary[500],
    background: theme.colors.grays[100],
    icon: Speed,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 2,
    name: "Battery Life",
    border: "none",
    background: theme.colors.grays[100],
    icon: Battery,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 3,
    name: "Appearance",
    border: "none",
    background: theme.colors.grays[100],
    icon: Eye,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 4,
    name: "Hardware",
    border: "none",
    background: theme.colors.grays[100],
    icon: SmallPhone,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 5,
    name: "OS & Software",
    border: "none",
    background: theme.colors.grays[100],
    icon: AppGrid,
    text: theme.colors.grays[900],
    fill: "",
  },
  {
    id: 6,
    name: "Build Quality",
    border: "none",
    background: theme.colors.grays[100],
    icon: Qaulity,
    text: theme.colors.grays[900],
    fill: "",
  },
];

export const ProductReviewData = [
  {
    id: 1,
    name: "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)",
    rating: "4.2",
    desc: "The best budget phone. Camera clarity is good. Value for money. Right size phone with light weight. Over all good phone with budget price.",
    date: "21 Sep, 2020",
    image: UserPhoneMotorola,
  },
  {
    id: 2,
    name: "REDMI Note 10S (Shadow Black, 64 GB)  (6 GB RAM)",
    rating: "4.2",
    desc: "The best budget phone. Camera clarity is good. Value for money. Right size phone with light weight. Over all good phone with budget price.",
    date: "24 May, 2020",
    image: UserPhoneRedmi,
  },
  {
    id: 3,
    name: "POCO C31 (Royal Blue, 64 GB) (4 GB RAM)",
    rating: "4.2",
    desc: "The best budget phone. Camera clarity is good. Value for money. Right size phone with light weight. Over all good phone with budget price.",
    date: "24 May, 2020",
    image: UserPhonePoco,
  },
  {
    id: 4,
    name: "POCO C31 (Royal Blue, 64 GB) (4 GB RAM)",
    rating: "4.2",
    desc: "The best budget phone. Camera clarity is good. Value for money. Right size phone with light weight. Over all good phone with budget price.",
    date: "24 May, 2020",
    image: UserPhonePoco,
  },
];

export const ProductShareData = [
  {
    id: 1,
    name: "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)",
    rating: "4.2",
    desc: "The best budget phone. Camera clarity is good. Value for money. Right size phone with light weight. Over all good phone with budget price.",
    date: "21 Sep, 2020",
    image: UserPhoneMotorola,
  },
];

export const list = [
  {
    id: 1,
    name: "Home",
    image: Home,
    marginLeft: "10px",
    selected: true,
  },
  {
    id: 2,
    name: "Phones",
    image: Phone,
    marginLeft: "10px",
    right: RightArrow,
    margin: "10px",
  },
  {
    id: 3,
    name: "Cars",
    image: Car,
    marginLeft: "10px",
    right: RightArrow,
  },
  {
    id: 4,
    name: "Motorbikes",
    image: Motorbike,
    marginLeft: "10px",
    right: RightArrow,
  },
  {
    id: 5,
    name: "Buying Guide",
    image: Search,
    marginLeft: "10px",
  },
  {
    id: 6,
    name: "Community",
    image: Community,
    marginLeft: "10px",
  },
  {
    id: 7,
    name: "About ReviewDale",
    image: About,
    marginLeft: "10px",
  },
  {
    id: 8,
    name: "Help & support",
    image: Help,
    marginLeft: "10px",
    right: RightArrow,
  },
];

export const news = [
  {
    id: 1,
    image: News1,
    text: "Amidst rumors, Tesla finally sets up a new factory in Bangalore",
  },
  {
    id: 2,
    image: News2,
    text: "Apple to launch a compact iPhone this November, but won’t be availab..",
  },

  {
    id: 4,
    image: News4,
    text: "iWatch sales to surpass Samsung Gear pretty soon in Europe",
  },
  {
    id: 5,
    image: News5,
    text: "BMW G310R to receive a facelift with a ton of new safety features",
  },
];

export const IP = "http://35.192.37.239:4000";
export const BASE_URL = "/api/v1/news";

export const errorTypes = {
  EMAIL_INVALID: "please enter a correct email address",
  PASSWORD_INVALID: "entered password is not correct",
  LEFT_BLANK: "cannot be left blank",
  PASSWORD_MISMATCH: "passwords are not matching",
  LEAST_CHARACTER_PASSWORD: "password should have atleast 6 characters",
  WEAK_PASSWORD:
    "your password is weak ( use atleast one uppercase, one lowercase and one alphanumeric characters)",
  STRONG_PASSWORD: "great!, now you have a strong password",
};

export const notSpecialCharacter = (string) => /[^a-zA-Z0-9]+/g.test(string);
