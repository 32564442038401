import * as actionTypes from "./actionTypes";

const initialState = {
  token: "",
  donated: true,
  activeIndex: 1,
  show: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case actionTypes.ACTIVE_INDEX: {
      return {
        ...state,
        activeIndex: action.payload,
      };
    }

    case actionTypes.DONATED_AMOUNT: {
      return {
        ...state,
        amount: action.payload,
      };
    }

    case actionTypes.SIGNOUT: {
      return {
        ...state,
        token: "",
        donated: false,
        toBeReload: true,
      };
    }

    case actionTypes.DONATE: {
      return {
        ...state,
        donated: true,
      };
    }

    case actionTypes.SHOW: {
      return {
        ...state,
        show: true,
      };
    }

    case actionTypes.ISRELOAD: {
      return {
        ...state,
        toBeReload: false,
      };
    }
    default: {
      return state;
    }
  }
};
