import React from "react";

const Eye = ({ fill = "#011726" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 4.99992C13.1583 4.99992 15.9749 6.77492 17.3499 9.58325C15.9749 12.3916 13.1583 14.1666 9.99992 14.1666C6.84158 14.1666 4.02492 12.3916 2.64992 9.58325C4.02492 6.77492 6.84158 4.99992 9.99992 4.99992ZM9.99992 3.33325C5.83325 3.33325 2.27492 5.92492 0.833252 9.58325C2.27492 13.2416 5.83325 15.8333 9.99992 15.8333C14.1666 15.8333 17.7249 13.2416 19.1666 9.58325C17.7249 5.92492 14.1666 3.33325 9.99992 3.33325ZM9.99992 7.49992C11.1499 7.49992 12.0833 8.43325 12.0833 9.58325C12.0833 10.7333 11.1499 11.6666 9.99992 11.6666C8.84992 11.6666 7.91658 10.7333 7.91658 9.58325C7.91658 8.43325 8.84992 7.49992 9.99992 7.49992ZM9.99992 5.83325C7.93325 5.83325 6.24992 7.51659 6.24992 9.58325C6.24992 11.6499 7.93325 13.3333 9.99992 13.3333C12.0666 13.3333 13.7499 11.6499 13.7499 9.58325C13.7499 7.51659 12.0666 5.83325 9.99992 5.83325Z"
        fill={fill}
        fill-opacity="0.3"
      />
    </svg>
  );
};

export default Eye;
