import styled from "styled-components";
import theme from "../../theme";

export const Slider = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 12px;
  border-radius: 40px;
  background: ${(props) =>
    `linear-gradient(to right, ${theme.colors.primary[500]} 0%, ${theme.colors.primary[500]} ${props.value}%, ${theme.colors.primary[200]} ${props.value}%, ${theme.colors.primary[200]} 100%);`};

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: radial-gradient(
      circle,
      #f7f7fc 40%,
      ${theme.colors.primary[500]} 45%
    );
    border-radius: 50%;
    border: 2px solid ${theme.colors.primary[500]};
  }

  ::-moz-range-thumb {
    width: 24px;
    height: 24px;
    -moz-appearance: none;
    background-image: radial-gradient(circle, #f7f7fc 60%, #ff9800 45%);
    border-radius: 50%;
    border: 2px solid ${theme.colors.primary[500]};
  }
`;
