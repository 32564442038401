import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Box from "ReusableComponents/Box";
import Button from "ReusableComponents/Button";
import Text from "ReusableComponents/Text";
import theme from "theme";
import NotFound from "assets/NotFound.svg";

const PageNotFound = ({ errorStatus, title, description }) => {
  const smallDevice = useMediaQuery("(max-width:450px)");

  return (
    <Box
      background={theme.colors.white[900]}
      zIndex={12}
      h="80vh"
      display="flex"
      alignItems="center"
    >
      <Grid container spacing={2} sx={{}}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: smallDevice ? "column" : "row",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            w="400px"
            marginRight={smallDevice ? 0 : "40px"}
            textAlign="center"
          >
            <Text
              fontSize={theme.typograpgy.heading_callout.size}
              fontWeight={theme.typograpgy.heading_callout.weight}
              margin={"8px 0"}
            >
              {errorStatus} Error
            </Text>
            <Text
              fontSize={theme.typograpgy.heading_1.size}
              fontWeight={theme.typograpgy.heading_1.weight}
              margin={"8px 0"}
            >
              {title}
            </Text>
            <Text
              fontSize={theme.typograpgy.body_extralarge.size}
              fontWeight={theme.typograpgy.body_extralarge.weight}
            >
              {description}
            </Text>
            <Button
              w={"129px"}
              h={"48px"}
              borderRadius={"6px"}
              border={"none"}
              m={"0px 10px 0px 0px"}
              background={theme.colors.primary[500]}
              cursor={"pointer"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => (window.location.href = "/")}
            >
              <Text
                textColor="white"
                fontSize={theme.typograpgy.overline_label.size}
                fontWeight={theme.typograpgy.overline_label.weight}
                marginBottom={"0px"}
              >
                HOMEPAGE
              </Text>
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            h="200px"
            marginBottom="30px"
            marginLeft={smallDevice ? 0 : "40px"}
          >
            <img src={NotFound} alt="not-found" style={{ height: "100%" }} />
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            h="200px"
            marginBottom="30px"
            paddingLeft="80px"
          >
            <img src={NotFound} alt="not-found" style={{ height: "100%" }} />
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default PageNotFound;
