import React from "react";
import { TextStyled } from "./styles";

const Text = ({ children, ...props }) => {
  return (
    <TextStyled {...props} data-testid="text">
      {children}
    </TextStyled>
  );
};

export default Text;
