/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";
import Button from "ReusableComponents/Button";
import Text from "ReusableComponents/Text";
import RatingBar from "ReusableComponents/RatingBar";
import DropDown from "ReusableComponents/DropDown";
import Checkbox from "ReusableComponents/Checkbox";
import theme from "theme";
import Routes from "routes";
import { AppStyled } from "style";
import "App.css";

import Input from "ReusableComponents/Input";
import Search from "ReusableComponents/Search";
import Separators from "ReusableComponents/Separators";
import InProgress from "Pages/InProgress";
import RangeSlider from "ReusableComponents/RangeSlider";
import TestSignin from "Pages/TestSignIn";
import { useDispatch, useSelector } from "react-redux";
import { showLogin } from "store/auth/actions";
import CustomSlider from "ReusableComponents/CustomSlider";

import CustomRange from "ReusableComponents/CustomSlider/Range";
import MenuDropdown from "ReusableComponents/Menu";
import ErrorPages from "ReusableComponents/ErrorPages";
import ConnectionLost from "assets/images/LostConnection.svg";
import Maintainence from "assets/images/Maintainence.svg";

const App = () => {
  const routes = true;
  const dispatch = useDispatch();

  const show = useSelector((state) => state.auth.show);
  const isProd = false;
  // for
  const env = process.env.REACT_APP_NODE_ENV;
  const isUnderDev = process.env.REACT_APP_UNDER_DEV;

  const getShow = (toShow) => {
    if (toShow) dispatch(showLogin());
  };

  const getVal = (val) => console.log(val[0]);

  return !window.navigator.onLine ? (
    <ErrorPages
      title={"Whoops! Lost connection..."}
      subtitle={
        "The page you’re looking for isn’t available or you may have lost internet connection"
      }
      img={ConnectionLost}
    />
  ) : env === "production" ? (
    // <ErrorPages
    //   title={"Site under maintenance"}
    //   subtitle={"We are sorry you had to see this page, we will be back soon!"}
    //   img={Maintainence}
    // />
    <InProgress />
  ) : routes ? (
    <AppStyled background={theme.colors.grays[100]}>
      {/* <Routes routes={routes} /> */}
      {show ? (
        <Routes routes={routes} />
      ) : !show || isUnderDev ? (
        <TestSignin getShow={getShow} />
      ) : (
        ""
      )}
    </AppStyled>
  ) : (
    <>
      {/* <Rating /> */}

      <Button
        textColor={theme.colors.grays[500]}
        borderRadius="6px"
        w="99px"
        h="36px"
        border={`1px solid ${theme.colors.primary[300]}`}
        cursor="pointer"
      >
        <Text
          fontWeight="500"
          fontSize={"14px"}
          textColor={theme.colors.primary[300]}
        >
          NORMAL
        </Text>
      </Button>

      {/* <SliderNew /> */}

      <Input
        placeholder="Text"
        h="48px"
        w="253px"
        p="14px 16px"
        borderRadius="6px"
        //background={theme.colors.grays[100]}
        //disabled
        lineHeight="20px"
      />
      <br />
      <Search
        placeholder="Text"
        h="48px"
        w="253px"
        p="14px 0px"
        borderRadius="6px"
        border={`1px solid ${theme.colors.grays[200]}`}
      />
      <br />
      <CustomSlider />
      <CustomRange
        getVal={getVal}
        primary={theme.colors.positive[500]}
        secondary={theme.colors.positive[200]}
        thumb={theme.colors.positive[500]}
      />

      <MenuDropdown />

      <RatingBar rating={3} />
      <br />

      <RangeSlider getVal={getVal} />

      <DropDown
        background="#FFFFFF"
        /* Gray/400 */

        border={`1px solid ${theme.colors.grays[900]}`}
        borderRadius="6px"
        w="253px"
        h="48px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        hoverBackground="white"
        options={[
          {
            id: 1,
            option: "option1",
          },
          {
            id: 2,
            option: "option2",
          },
          {
            id: 3,
            option: "option3",
          },
          {
            id: 4,
            option: "option4",
          },
        ]}
      />
      <br />

      <Checkbox
        name="this is label"
        type="radio"
        w="20px"
        h="20px"
        border={`2px solid ${theme.colors.primary[500]}`}
        borderRadius="50%"
        m="0px 10px"
        boxSizing="border-box"
        p=" 2px"
        color={theme.colors.primary[500]}
      />

      <Checkbox
        w="20px"
        h="20px"
        type="checkbox"
        border={`2px solid ${theme.colors.grays[500]}`}
        borderRadius="2px"
        color={theme.colors.primary[500]}
      />

      <Checkbox type="switch" />
      <Separators h="6px" w="80px" background={theme.colors.grays[100]} />
      {/* <Modal /> */}
    </>
  );
};

export default App;
