import styled from "styled-components";
import theme from "../../theme";

export const DropDownStyled = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ border }) => border};
  margin: ${({ margin }) => margin};
  diplay: ${({ diplay }) => diplay};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  background-color: ${({ background = "white" }) => background};

  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};

  min-width: ${({ minw }) => minw};
  max-width: ${({ maxw }) => maxw};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const DropDownOptionsStyled = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  z-index: ${({ zIndex }) => zIndex};
  position: ${({ position }) => position};
  min-height: ${({ minHeight }) => minHeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  box-shadow: ${({ boxShadow }) => boxShadow};
  diplay: ${({ diplay }) => diplay};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  background-color: ${({ background = "white" }) => background};

  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const DropDownListStyled = styled.li`
  border-radius: ${({ borderRadius }) => borderRadius};
  position: ${({ position }) => position};
  cursor: ${({ cursor }) => cursor};
  list-style: ${({ listStyle }) => listStyle};
  min-height: ${({ minHeight }) => minHeight};
  margin: ${({ margin }) => margin};
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  box-shadow: ${({ boxShadow }) => boxShadow};
  diplay: ${({ diplay }) => diplay};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  background-color: ${({ background = "white" }) => background};

  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};

  &:hover {
    background-color: ${(props) => props.hoverBackground};
  }

  ${({ isFocus }) =>
    isFocus &&
    `&:hover {
    background-color: ${({ hoverBackground }) => hoverBackground};
}`}
`;

export const DropDownStyledDiv = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border }) => border};
  display: ${({ display }) => display};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  &:focus {
    border: 1px solid ${theme.colors.grays[400]};
  }
  &:focus {
    border: 1px solid ${theme.colors.primary[500]};
  }
`;

export const DropdownIconStyled = styled.span`
  display: ${({ display }) => display};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};

  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;
