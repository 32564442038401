/* eslint-disable */

import React, { useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import { Spin, Steps } from "antd";
import { Helmet } from "react-helmet";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import theme from "theme";
import Speed from "assets/Speed.svg";
import Battery from "assets/Battery.svg";
import RangeSlider from "ReusableComponents/RangeSlider";
import PhoneSmall from "assets/PhoneSmall.svg";
import AppGrid from "assets/AppGrid.svg";
import BuildQuality from "assets/BuildQuality.svg";
import Eye from "assets/Eye.svg";
import StarColored from "assets/StarColored";
import Button from "ReusableComponents/Button";
import AddReview1 from "../AddReview1";
import Drop from "ReusableComponents/Drop";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import Separators from "ReusableComponents/Separators";
import { ratings } from "store/rating/actions";
import SingleRangeSlider from "ReusableComponents/SingleRangeSlider";
import CustomRange from "ReusableComponents/CustomSlider/Range";
import { recentlyViewed } from "Pages/Phones/constants";
const { Step } = Steps;

const steps = [
  {
    title: "Rate",
  },
  { title: "Review" },
];

const ratingWords = ["Bad", "Below Par", "Average", "Good", "Excellent"];

const AddReview = ({ data, getDragActive }) => {
  console.log("🚀 ~ file: index.js ~ line 39 ~ AddReview ~ data", data);
  const dispatch = useDispatch();
  const ratingState = useSelector(({ rating }) => rating);
  const [current, setCurrent] = React.useState(0);
  const [ratingValues, setRatingValues] = React.useState({
    r1: 0,
    type: "",
    r2: 0,
    r3: 0,
    r4: 0,
    r5: 0,
    r6: 0,
    r1main: 0,
    r2main: 0,
    r3main: 0,
    r4main: 0,
    r5main: 0,
    r6main: 0,
    r1secondary: 0,
    r2secondary: 0,
    r3secondary: 0,
    r4secondary: 0,
    r5secondary: 0,
    r6secondary: 0,
    r1per: 0,
    r2per: 0,
    r3per: 0,
    r4per: 0,
    r5per: 0,
    r6per: 0,
    overall: 0,
    overallmain: 0,
    overallsecondary: 0,
  });

  useEffect(() => {
    if (ratingValues) {
      setMainColor(ratingValues.type);
    }
  }, [ratingValues]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const setStarColor = () => {
    if (ratingValues.overall >= 0 && ratingValues.overall <= 1)
      return theme.colors.negative[500];
    if (ratingValues.overall > 1 && ratingValues.overall <= 2)
      return theme.colors.caution[500];
    if (ratingValues.overall > 2 && ratingValues.overall <= 3)
      return theme.colors.positive[500];
    if (ratingValues.overall > 3 && ratingValues.overall <= 4)
      return theme.colors.primary[500];
    else return theme.colors.gradients.rainbow;
  };

  const setWord = () => {
    if (ratingValues.overall == 0) {
      return "Bad";
    }
    if (ratingValues.overall > 0 && ratingValues.overall <= 1)
      return ratingWords[0];
    if (ratingValues.overall > 1 && ratingValues.overall <= 2)
      return ratingWords[1];
    if (ratingValues.overall > 2 && ratingValues.overall <= 3)
      return ratingWords[2];
    if (ratingValues.overall > 3 && ratingValues.overall <= 4)
      return ratingWords[3];
    else return ratingWords[4];
  };

  useEffect(() => {
    setRatingValues(ratingState);
  }, []);

  const setMainColor = (type) => {
    if (type) {
      if (ratingValues[type] >= 0.0 && ratingValues[type] <= 1.0)
        return theme.colors.negative[500];
      if (ratingValues[type] > 1.1 && ratingValues[type] <= 2.0)
        return theme.colors.caution[500];
      if (ratingValues[type] > 2.1 && ratingValues[type] <= 3.0)
        return theme.colors.positive[500];
      if (ratingValues[type] > 3 && ratingValues[type] <= 4)
        return theme.colors.primary[500];
      else
        return "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%);";

      //"linear-gradient(90deg, #FF4542 0%, #FBAF2D 25%, #2CB559 49.48%, #0598FA 73.44%, #4C46FA 100%)";
    }
  };

  const setSecondaryColor = (type) => {
    if (ratingValues[type] == 0) {
      return theme.colors.negative[200];
    }
    if (ratingValues[type] > 0 && ratingValues[type] <= 1)
      return theme.colors.negative[200];
    if (ratingValues[type] > 1 && ratingValues[type] <= 2)
      return theme.colors.caution[200];
    if (ratingValues[type] > 2 && ratingValues[type] <= 3)
      return theme.colors.positive[200];
    if (ratingValues[type] > 3 && ratingValues[type] <= 4)
      return theme.colors.primary[200];
    else return theme.colors.primary[200];
  };

  const getVal = (val, type) => {
    console.log("type, val", type, val);
    const main = setMainColor(type);
    console.log("🚀 ~ file: index.js ~ line 161 ~ getVal ~ main", main);
    const secondary = setSecondaryColor(type);
    console.log(
      "🚀 ~ file: index.js ~ line 163 ~ getVal ~ secondary",
      secondary
    );
    setRatingValues({
      ...ratingValues,
      type,
      [type]: val[0],
      [`${type}per`]: val[0],
      [`${type}main`]: main,
      [`${type}secondary`]: secondary,
    });
    dispatch(
      ratings({
        type,
        percentType: `${type}per`,
        colorTypeMain: `${type}main`,
        colorTypeSecory: `${type}secondary`,
        data: {
          range: val[0],

          mainColor: setMainColor(type),
          secondaryColor: setSecondaryColor(type),
        },
      })
    );
  };

  return (
    <>
      <Container>
        <Box h="auto" w="100%">
          {/* steps wrapper */}
          <Box w="20%" margin="20px auto">
            <Steps current={current} labelPlacement="vertical">
              {steps.map((item) => (
                <Step
                  key={item.title}
                  title={item.title}
                  onClick={() => {
                    if (current) {
                      prev();
                    }
                  }}
                  style={{ cursor: current ? "pointer" : "not-allowed" }}
                />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <img
                  src={
                    data?.imageUrl[0]
                      ? data?.imageUrl[0]
                      : recentlyViewed[0].Image
                  }
                  height="300px"
                  style={{ margin: "0 auto" }}
                />
                <Text
                  fontSize={theme.typograpgy.heading_2.size}
                  fontWeight={theme.typograpgy.heading_2.weight}
                  textColor={theme.colors.grays[700]}
                  margin={"24px 0"}
                >
                  {data["Brand Name"]
                    ? data["Brand Name"]
                    : "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)"}
                </Text>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              {current === 0 ? (
                <Box>
                  <Box marginBottom="36px">
                    <Text
                      fontSize={theme.typograpgy.heading_2.size}
                      fontWeight={theme.typograpgy.heading_2.weight}
                      marginBottom={"0px"}
                    >
                      Rate attributes
                    </Text>
                    <Text
                      textColor={theme.colors.grays[600]}
                      fontSize={theme.typograpgy.body.size}
                      fontWeight={theme.typograpgy.body.weight}
                      marginBottom={"0px"}
                    >
                      Please rate the experience of the product on each
                      attribute seperately
                    </Text>
                  </Box>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex">
                            <img alt="img" src={Speed} />
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              Performance
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              {ratingValues.r1}
                            </Text>
                          </Box>
                        </Box>
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="r1"
                            mainColor={ratingValues.r1main}
                            secondaryColor={ratingValues.r1secondary}
                            rating={ratingValues.r1}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex">
                            <img alt="img" src={Battery} />
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              Battery Life
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              {ratingValues.r2}
                            </Text>
                          </Box>
                        </Box>
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="r2"
                            rating={ratingValues.r2}
                            percent={ratingValues.r2per}
                            mainColor={ratingValues.r2main}
                            secondaryColor={ratingValues.r2secondary}
                          />
                          {/* <RangeSlider
                        getValue={getVal}
                        type="r2"
                        rating={ratingValues.r2}
                        percent={ratingValues.r2per}
                        mainColor={ratingValues.r2main}
                        secondaryColor={ratingValues.r2secondary}
                      /> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex">
                            <img alt="img" src={AppGrid} />
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              Hardware
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              {ratingValues.r3}
                            </Text>
                          </Box>
                        </Box>
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="r3"
                            rating={ratingValues.r3}
                            mainColor={ratingValues.r3main}
                            percent={ratingValues.r3per}
                            secondaryColor={ratingValues.r3secondary}
                          />
                          {/* <RangeSlider
                        getValue={getVal}
                        type="r3"
                        rating={ratingValues.r3}
                        mainColor={ratingValues.r3main}
                        percent={ratingValues.r3per}
                        secondaryColor={ratingValues.r3secondary}
                      /> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex">
                            <img alt="img" src={PhoneSmall} />
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              Software & OS
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              {ratingValues.r4}
                            </Text>
                          </Box>
                        </Box>
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="r4"
                            rating={ratingValues.r4}
                            mainColor={ratingValues.r4main}
                            secondaryColor={ratingValues.r4secondary}
                            percent={ratingValues.r4per}
                          />
                          {/* <RangeSlider
                        getValue={getVal}
                        type="r4"
                        rating={ratingValues.r4}
                        mainColor={ratingValues.r4main}
                        secondaryColor={ratingValues.r4secondary}
                        percent={ratingValues.r4per}
                      /> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex">
                            <img alt="img" src={BuildQuality} />
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              Build Quality
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              {ratingValues.r5}
                            </Text>
                          </Box>
                        </Box>
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="r5"
                            rating={ratingValues.r5}
                            mainColor={ratingValues.r5main}
                            percent={ratingValues.r5per}
                            secondaryColor={ratingValues.r5secondary}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex">
                            <img alt="img" src={Eye} />
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              Appearance
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              textColor={theme.colors.grays[900]}
                              fontSize={theme.typograpgy.body.size}
                              fontWeight={theme.typograpgy.body.weight}
                              margin="0px"
                              marginLeft="5px"
                            >
                              {ratingValues.r6}
                            </Text>
                          </Box>
                        </Box>
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="r6"
                            rating={ratingValues.r6}
                            mainColor={ratingValues.r6main}
                            secondaryColor={ratingValues.r6secondary}
                            percent={ratingValues.r6per}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Separators
                    h="2px"
                    w="100%"
                    marginTop="24px"
                    background={theme.colors.grays[100]}
                  />
                  <Box marginTop="20px">
                    <Text
                      fontSize={theme.typograpgy.heading_2.size}
                      fontWeight={theme.typograpgy.heading_2.weight}
                      marginBottom={"0px"}
                    >
                      Rate overall experience
                    </Text>
                    <Text
                      textColor={theme.colors.grays[600]}
                      fontSize={theme.typograpgy.body.size}
                      fontWeight={theme.typograpgy.body.weight}
                      marginBottom={"0px"}
                    >
                      Please rate the overall experience of the product on value
                      for cost
                    </Text>
                  </Box>
                  <Box marginTop="20px">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Box w="100%">
                          <SingleRangeSlider
                            getValue={getVal}
                            type="overall"
                            rating={ratingValues.overall}
                            mainColor={ratingValues.overallmain}
                            secondaryColor={ratingValues.overallsecondary}
                            percent={ratingValues.overallper}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="center">
                          <StarColored
                            fill={
                              setStarColor().includes("linear")
                                ? theme.colors.primary[500]
                                : setStarColor()
                            }
                          />
                          <Box marginLeft="20px">
                            <Text
                              textColor={setStarColor()}
                              gradients={
                                setStarColor().includes("linear") ? true : false
                              }
                              fontSize={theme.typograpgy.heading_callout.size}
                              fontWeight={
                                theme.typograpgy.heading_callout.weight
                              }
                              marginBottom={"0px"}
                            >
                              {ratingValues.overall}
                            </Text>
                          </Box>
                          <Box marginLeft="20px">
                            <Text
                              textColor={setStarColor()}
                              gradients={
                                setStarColor().includes("linear") ? true : false
                              }
                              fontSize={theme.typograpgy.heading_callout.size}
                              fontWeight={
                                theme.typograpgy.heading_callout.weight
                              }
                              marginBottom={"0px"}
                            >
                              {setWord()}
                            </Text>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Drop prev={prev} data={data} getDragActive={getDragActive} />
              )}
            </Grid>
          </Grid>
        </Box>

        <Helmet>
          <style type="text/css">{`
       .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 38px;
      }      
        .ant-modal-wrap {
        overflow: hidden;
        max-height: 900px;
        }
        .ant-modal {
          top: 0px;
          margin: 0 auto;
          width: 100%;
          max-width: 1800px
         
        } 
      .ant-modal-body {
        padding: 24px;
        font-size: 14px;
        line-height: 1.5715;
        height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;
        word-wrap: break-word;
      }
    
      .ant-steps-item-title {
        color: ${theme.colors.primary[500]} !important;
      }
    `}</style>
        </Helmet>
      </Container>
      {!current ? (
        <Box display="flex" justifyContent="end">
          <Button
            w={"120px"}
            h={"48px"}
            borderRadius={"6px"}
            background={theme.colors.primary[500]}
            border={`1px solid ${theme.colors.primary[500]}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            m={"48px 48px 0px auto"}
            marginTop={"48px"}
            cursor={"pointer"}
            onClick={next}
          >
            <Text
              textColor={"white"}
              fontSize={theme.typograpgy.text_button.size}
              fontWeight={theme.typograpgy.text_button.weight}
              marginBottom={"0px"}
            >
              Save Rating
            </Text>
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          margin="48px 48px 0px 48px"
        >
          <Button
            w={"120px"}
            h={"48px"}
            borderRadius={"6px"}
            background={"white"}
            border={`1px solid ${theme.colors.primary[500]}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            onClick={prev}
          >
            <Text
              textColor={theme.colors.primary[500]}
              fontSize={theme.typograpgy.text_button.size}
              fontWeight={theme.typograpgy.text_button.weight}
              marginBottom={"0px"}
            >
              Back
            </Text>
          </Button>
          <Button
            w={"120px"}
            h={"48px"}
            borderRadius={"6px"}
            background={theme.colors.primary[500]}
            border={`1px solid ${theme.colors.primary[500]}`}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            onClick={prev}
          >
            <Text
              textColor={"white"}
              fontSize={theme.typograpgy.text_button.size}
              fontWeight={theme.typograpgy.text_button.weight}
              marginBottom={"0px"}
            >
              Publish Review
            </Text>
          </Button>
        </Box>
      )}
    </>
  );
};

export default AddReview;
