import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const BaiscTabs = ({ tabs, activeIndex }) => {
  const callback = (key) => {
    activeIndex(key);
  };
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      {tabs.map(({ id, tab, component }) => (
        <TabPane tab={tab} key={id}>
          {component}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default BaiscTabs;
