import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Box from "ReusableComponents/Box";
import theme from "theme";
import Text from "ReusableComponents/Text";
import Button from "ReusableComponents/Button";

const ErrorPages = ({ title, subtitle, img }) => {
  const smallDevice = useMediaQuery("(max-width:450px)");

  return (
    <Box
      background={theme.colors.white[900]}
      marginTop="50px"
      zIndex={12}
      display="flex"
      alignItems="center"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display="flex" justifyContent="start" alignItems="center">
            <img
              src={img}
              alt="not-found"
              style={{
                height: "100%",
                width: smallDevice && "80%",
                margin: "0 auto",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginRight={smallDevice ? 0 : "40px"}
            textAlign="center"
          >
            <Text
              fontSize={smallDevice ? theme.spacing[500] : "44px"}
              fontWeight={"600"}
              margin={"8px 0"}
            >
              {title}
            </Text>
            <Text
              fontSize={
                smallDevice
                  ? theme.typograpgy.body_roboto.size
                  : theme.typograpgy.body_extralarge.size
              }
              fontWeight={theme.typograpgy.body_extralarge.weight}
            >
              {subtitle}
            </Text>
            <Button
              w={"129px"}
              h={"48px"}
              borderRadius={"6px"}
              border={"none"}
              m={"0px 10px 0px 0px"}
              background={theme.colors.primary[500]}
              cursor={"pointer"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => (window.location.href = "/")}
            >
              <Text
                textColor="white"
                fontSize={theme.typograpgy.overline_label.size}
                fontWeight={theme.typograpgy.overline_label.weight}
                marginBottom={"0px"}
              >
                Go to home
              </Text>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorPages;
