export const COMPARE_PRODUCTS = "COMPARE_PRODUCTS";
export const REMOVE_FROM_COMPARE_PRODUCTS = "REMOVE_FROM_COMPARE_PRODUCTS";

export const CHECK_PRODUCT_DISPLAY = "CHECK_PRODUCT_DISPLAY";
export const CHECK_PRODUCT_DISPLAY_ALL = "CHECK_PRODUCT_DISPLAY_ALL";

export const CHECK_PRODUCT_CONNECTIVITY = "CHECK_PRODUCT_CONNECTIVITY";
export const CHECK_PRODUCT_CONNECTIVITY_ALL = "CHECK_PRODUCT_CONNECTIVITY_ALL";

export const CHECK_PRODUCT_CAMERA = "CHECK_PRODUCT_CAMERA";
export const CHECK_PRODUCT_CAMERA_ALL = "CHECK_PRODUCT_CAMERA_ALL";

export const CHECK_PRODUCT_GENERAL = "CHECK_PRODUCT_GENERAL";
export const CHECK_PRODUCT_GENERAL_ALL = "CHECK_PRODUCT_GENERAL_ALL";

export const CHECK_PRODUCT_PERFORMANCE = "CHECK_PRODUCT_PERFORMANCE";
export const CHECK_PRODUCT_PERFORMANCE_ALL = "CHECK_PRODUCT_PERFORMANCE_ALL";

export const CHECK_PRODUCT_REVIEWS = "CHECK_PRODUCT_REVIEWS";
export const CHECK_PRODUCT_REVIEWS_ALL = "CHECK_PRODUCT_REVIEWS_ALL";

export const CHECK_PRODUCT_STORAGE = "CHECK_PRODUCT_STORAGE";
export const CHECK_PRODUCT_STORAGE_ALL = "CHECK_PRODUCT_STORAGE_ALL";
