/* eslint-disable */
import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  type: "brand",
  thumbnails: [],
};

const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MEDIA_TYPE: {
      const newState = {
        ...state,
        type: action.payload,
      };

      return newState;
    }
    case actionTypes.THUMBNAILS: {
      const newState = {
        ...state,
        thumbnails: action.payload,
      };

      return newState;
    }
    case actionTypes.CLEAR_THUMBNAILS: {
      return {
        ...state,
        thumbnails: [],
      };
    }
    case actionTypes.DELETE_THUMBNAIL: {
      if (state.thumbnails.length)
        if (_.isEmpty(state.thumbnails[0]))
          return {
            ...state,
            thumbnails: [],
          };
        else
          return {
            ...state,
            thumbnails: state.thumbnails.filter(
              (item) => item.id !== action.payload
            ),
          };
    }
    default: {
      return state;
    }
  }
};

export default MediaReducer;
