/* eslint-disable */
import * as actionTypes from "./actionTypes";
import _ from "lodash";
import { buyingTypes, attributeTypes } from "../../Pages/Landing/constants";
import theme from "../../theme";

const initialState = {
  selecetedCategories: [],
  categories: buyingTypes,
  attributes: attributeTypes,
  selecetedAttributes: [],
  min: 0,
  max: 100,
};

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PREFERENCES: {
      const { text, border, background, name, fill } = action.payload;

      const isPresent = state.selecetedCategories.includes(name);

      return {
        ...state,
        categories: state.categories.map((item) =>
          item.name === name
            ? { ...item, text, border, background, fill }
            : item
        ),
        selecetedCategories: !isPresent
          ? [...state.selecetedCategories, name]
          : state.selecetedCategories.filter((item) => item !== name),
      };
    }

    case actionTypes.SELECT_CATEGORY: {
      const { name } = action.payload;

      const isPresent = state.selecetedCategories.includes(name);

      const updateCategories = state.categories.map((item) =>
        item.name === name
          ? {
              ...item,
              text: theme.colors.primary[500],
              border: `1px solid ${theme.colors.primary[500]}`,
              background: theme.colors.white[900],
              fill: theme.colors.primary[500],
            }
          : {
              ...item,
              text: theme.colors.grays[900],
              border: "none",
              background: theme.colors.grays[100],
              fill: "#B2B2B2",
            }
      );

      return {
        ...state,
        categories: updateCategories,

        selecetedCategories: !isPresent ? [name] : state.selecetedCategories,
      };
    }

    case actionTypes.CLEAR: {
      return {
        ...state,
        selecetedAttributes: [],
        attributes: attributeTypes,
        selecetedCategories: [],
        categories: buyingTypes,
      };
    }

    case actionTypes.SELECT_ATTRIBUTE: {
      const { name } = action.payload;

      const isPresent = state.selecetedAttributes.includes(name);

      const attrCopy = state.attributes;

      for (let i = 0; i < attrCopy.length; i++) {
        if (state.selecetedAttributes.length < 3) {
          if (attrCopy[i].name === name) {
            attrCopy[i].text = !isPresent
              ? theme.colors.primary[500]
              : theme.colors.grays[900];
            attrCopy[i].border = !isPresent
              ? `1px solid ${theme.colors.primary[500]}`
              : "none";
            attrCopy[i].background = !isPresent
              ? theme.colors.white[900]
              : theme.colors.grays[100];
            attrCopy[i].fill = !isPresent ? theme.colors.primary[500] : "";
          }
        }
        if (state.selecetedAttributes.length === 3 && isPresent) {
          if (attrCopy[i].name === name) {
            attrCopy[i].text = theme.colors.grays[900];
            attrCopy[i].border = "none";
            attrCopy[i].background = theme.colors.grays[100];
            attrCopy[i].fill = "";
          }
        }
      }

      const filterArr = state.selecetedAttributes.filter(
        (item) => item !== name
      );

      const updatedSelectedAttributes =
        !isPresent && state.selecetedAttributes.length < 3
          ? [...state.selecetedAttributes, name]
          : filterArr;

      return {
        ...state,
        attributes: attrCopy,
        selecetedAttributes: updatedSelectedAttributes,
      };
    }

    case actionTypes.BUDGET: {
      const { min, max } = action.payload;
      return {
        ...state,
        min,
        max,
      };
    }

    default: {
      return state;
    }
  }
};

export default attributesReducer;
