import Phone1 from "assets/reviews/Phone1.svg";
import Phone2 from "assets/reviews/Phone2.svg";

export const recentlyViewed = [
  {
    id: 1,
    Image: Phone1,
    name: "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)",
    rating: 4.2,
  },
  {
    id: 2,
    Image: Phone2,
    name: "Redmi 10 Prime (Phantom Black 4GB RAM 64GB | Helio G88 with extendable RAM Upto 2GB | FHD+ 90Hz Adaptive Sync Display)",
    rating: 4.2,
  },
  {
    id: 3,
    Image: Phone1,
    name: "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)",
    rating: 4.2,
  },
  {
    id: 4,
    Image: Phone1,
    name: "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)",
    rating: 4.2,
  },
  {
    id: 5,
    Image: Phone2,
    name: "Redmi 10 Prime (Phantom Black 4GB RAM 64GB | Helio G88 with extendable RAM Upto 2GB | FHD+ 90Hz Adaptive Sync Display)",
    rating: 4.2,
  },
  {
    id: 6,
    Image: Phone1,
    name: "MOTOROLA G60 (Moonless, 128 GB) (6 GB RAM)",
    rating: 4.2,
  },
];
