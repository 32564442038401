/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Upload } from "antd";
import { useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import Box from "../../ReusableComponents/Box";
import Text from "../../ReusableComponents/Text";
import axios from "axios";
import AddReview1 from "../Product/Reviews/AddReview1";

const Profile = ({ isMedia = true, isLogo = false }) => {
  const token = useSelector(({ auth }) => auth.token);
  const [fileList, setFileList] = useState([]);
  const [fileVersions, setFileVersions] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [i, setI] = useState("");

  const uploadRef = useRef();

  useEffect(() => {
    if (fileVersions.length) {
      const images = fileVersions.map((i) => ({
        src: i.src,
        height: i.height,
        width: i.width,
      }));

      localStorage.setItem("images", images);
    }
  }, [fileVersions]);

  const setAspectRatio = (img, expected) => {
    // No support for IE8 and lower
    if (img.naturalWidth === "undefined") return;

    // Get natural dimensions of image
    var width = img.naturalWidth;
    var height = img.naturalHeight;

    // Examine if width is larger than height then reduce the width but fix the height
    if (width > height) {
      img.style.width = (width / height) * expected + "px";
      img.style.height = expected + "px";

      // horizontally center the image
      img.style.transform =
        "translatex(-" +
        parseInt((width / height) * expected - expected) +
        "px)";
      img.style.webkitTransform =
        "translateX(-" +
        parseInt((width / height) * expected - expected) +
        "px)";
      return img;
    }

    // Examine if height is larger than width then reduce the height but fix the width
    else if (height > width) {
      img.style.width = expected + "px";
      img.style.height = (height / width) * expected + "px";

      // vertically center the image
      img.style.transform =
        "translateY(-" +
        parseInt((height / width) * expected - expected) +
        "px)";
      img.style.webkitTransform =
        "translateY(-" +
        parseInt((height / width) * expected - expected) +
        "px)";
      return img;
    }

    // Or return fix width and height
    else {
      img.style.width = expected + "px";
      img.style.height = expected + "px";
      return img;
    }
  };

  const setHeight = (index) => {
    switch (Number(index)) {
      case 0:
        return 800;
      case 1:
        return 400;
      case 2:
        return 256;
      case 3:
        return 128;
      case 4:
        return 48;
    }
  };

  const setWidth = (index) => {
    switch (Number(index)) {
      case 0:
        return 800;
      case 1:
        return 400;
      case 2:
        return 256;
      case 3:
        return 128;
      case 4:
        return 48;
    }
  };

  const getSrc = async (file) =>
    await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => {
        return resolve(reader.result);
      };
    });

  const uploadMedia = async (file) => {
    console.log("this file", file);
    const blob = await fetch(file).then((res) => res.blob());

    console.log("is blob", blob instanceof Blob);

    const data = new FormData();
    data.append("file", file);

    // formData.append("img", file);
    // Post the form, just make sure to set the 'Content-Type' header
    await axios
      .post("http://localhost:4000/api/v1/user/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ` + token,
        },
      })
      .then((r) => console.log(r))
      .catch((err) => console.log(err));

    // Prints "yinyang.png"
    // console.log("res.data", res.data);
  };

  const onChange = async ({ fileList: newFileList }) => {
    setInvalid(false);
    if (!newFileList.length) {
      setFileList([]);
      setFileVersions([]);
      return;
    }
    console.log("nfl", newFileList);
    uploadMedia(newFileList[0].originFileObj);
    var blob = newFileList[0]?.originFileObj;
    setI(newFileList[0]);

    const totalSizeMB = blob?.size / Math.pow(1024, 2);
    console.log("totalSizeMB", totalSizeMB);

    var img = new Image();
    // set the onload *before* setting the img 'src'
    img.onload = (e) => {
      console.log(`Your lovely image is ${img.width} x ${img.height}px`);
      // if (img?.width && img?.height) {
      //   if (isLogo) {
      //     if (!invalid)
      //       if ((img.width > 48 && img.height > 48) || totalSizeMB > 1) {
      //         console.log("Logo should not exceed 1 mb and 48x48px");
      //         setInvalid(true);
      //       }
      //   } else if (isMedia) {
      //     if (!invalid)
      //       if ((img.width < 800 && img.height < 800) || totalSizeMB > 25) {
      //         console.log("Media should not exceed 25 mb and 800x800px");
      //         setInvalid(true);
      //       }
      //   }
      // }
    };
    setAspectRatio(img);

    img.src = window.URL.createObjectURL(blob);

    setFileList(newFileList);
    const files = newFileList;
    const arr = [files[0], files[0], files[0], files[0], files[0]];
    //setFileVersions(arr);

    const newarr = arr;

    let imgArray = [];
    // newarr.map((file, index) => {

    for (let file in newarr) {
      let image = new Image();
      const src = await getSrc(newarr[file]);
      image.src = src;
      image.height = setHeight(file);
      image.width = setWidth(file);

      imgArray = [...imgArray, image];
    }
    setFileVersions(imgArray);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    }
    const image = new Image();
    image.src = src;

    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  console.log(fileVersions);
  console.log(fileList);

  return (
    <Box background="white" margin="20px">
      <ImgCrop rotate>
        <Upload
          ref={uploadRef}
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          //action="https//jsonplaceholder.typicode.com/posts/"
          listType="none"
          openFileDialogOnClick={false}
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          accept="image/jpeg"
        >
          {/* <AddReview1 /> */}
          {fileList.length === 0 && "+ Add image"}
        </Upload>
      </ImgCrop>
      {!invalid ? (
        <Box display="flex" flexDirection="column" margin="10px 0">
          {fileVersions.length > 0 &&
            fileVersions.map((image) => {
              return (
                <div dangerouslySetInnerHTML={{ __html: image.outerHTML }} />
              );
            })}
        </Box>
      ) : (
        <Box>
          <Text>
            {isLogo
              ? "Logo should not exceed 1 mb and 48x48px"
              : "Media should not exceed 25 mb and 800x800px"}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default Profile;
