import styled from "styled-components";

export const LabelStyled = styled.label`
  display: ${({ display }) => display};
  cursor: ${({ cursor }) => cursor};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  border-radius: ${({ borderRadius }) => borderRadius};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  for: ${({ labelFor }) => labelFor};
  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const RadioStyled = styled.div`
  display: ${({ display }) => display};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-sizing: ${({ boxSizing }) => boxSizing};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};

  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
  ${({ checked, color }) =>
    checked &&
    `&::after {
    height: 100%;
    width: 100%;
    content: "";
    display: block;
    background: ${color};
    border-radius: 50%;
  }`}
`;

export const CheckboxStyled = styled.div`
  display: ${({ display }) => display};
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-sizing: ${({ boxSizing }) => boxSizing};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};

  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const CheckedIconStyled = styled.div`
  display: ${({ display }) => display};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};

  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const SwitchStyled = styled.input`
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  appearance: ${({ appearnace }) => appearnace};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  content: ${({ content }) => content};
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) => transition};
  line-height: ${({ lineHeight }) => lineHeight};

  &::before {
    cursor: pointer;
  content: "";
  width: 8px;
  position: absolute;
  height: 8px;
  border: 1px solid #9bd6fd;
  border-radius: 20px;
  background-color: white;
  transform: scale(2);
  transition: 0.5s;
}

&::before {
  cursor: pointer;
content: "";
width: 8px;
position: absolute;
height: 8px;
border: 1px solid #9bd6fd;
border-radius: 20px;
background-color: white;
transform: scale(2);
transition: 0.5s;
}
  }
`;
