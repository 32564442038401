import styled from "styled-components";

export const SeparatorStyled = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  position: ${({ position }) => position};
  position: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  left: ${({ left }) => left};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;
