import React from "react";

const SmallPhone = ({ fill = "#011726" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 0.833252H6.66675C5.28341 0.833252 4.16675 1.94992 4.16675 3.33325V16.6666C4.16675 18.0499 5.28341 19.1666 6.66675 19.1666H13.3334C14.7167 19.1666 15.8334 18.0499 15.8334 16.6666V3.33325C15.8334 1.94992 14.7167 0.833252 13.3334 0.833252ZM14.1667 14.9999H5.83341V3.33325H14.1667V14.9999ZM11.6667 17.4999H8.33342V16.6666H11.6667V17.4999Z"
        fill={fill}
        fill-opacity="0.4"
      />
    </svg>
  );
};

export default SmallPhone;
