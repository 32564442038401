/* eslint-disable */

import { Grid } from "@material-ui/core";
import React from "react";
import Box from "../../ReusableComponents/Box";
import Button from "../../ReusableComponents/Button";
import Input from "../../ReusableComponents/Input";
import Text from "../../ReusableComponents/Text";
import theme from "../../theme";
import Logo from "../../assets/Logo.svg";
import { emailAndPasswords } from "./constants";

const TestSignin = ({ getShow }) => {
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) return;
    setError(false);
    setLoading(true);

    const isPresent = emailAndPasswords.find(
      (item) => item.email === username && item.password === password
    );
    if (isPresent) {
      setLoading(false);
      getShow(true);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const handleChangeUsername = (e) => {
    const val = e.target.value;
    setUserName(val);
  };
  const handleChangePassword = (e) => {
    const val = e.target.value;
    setPassword(val);
  };
  return (
    <Grid
      container
      spaciding={2}
      style={{
        background: theme.colors.white[900],
      }}
    >
      <Box margin="0 auto">
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            flexGrow="1"
            margin="20px auto"
          >
            <img alt="img" src={Logo} height="48px" width="48px" />
            <Text
              fontSize={theme.typograpgy.heading_2.size}
              fontWeight={theme.typograpgy.heading_2.weight}
              margin={0}
              marginTop="5px"
            >
              ReviewDale
            </Text>
            <Text
              fontSize={theme.typograpgy.body.size}
              fontWeight={theme.typograpgy.body.weight}
              margin={0}
            >
              Home of your products
            </Text>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box margin="24px auto" w="400px" padding="12px">
              <Box>
                <Box display="flex" flexDirection="column">
                  <label htmlFor="username" style={{ marginBottom: 8 }}>
                    <Text
                      fontSize={theme.typograpgy.body.size}
                      fontWeight={theme.typograpgy.body.weight}
                      textColor={theme.colors.grays[600]}
                      margin={0}
                    >
                      Username/email
                    </Text>
                  </label>
                  <Input
                    tabIndex={1}
                    id="username"
                    h="48px"
                    mw="568px"
                    type="text"
                    p="14px 16px"
                    borderRadius="6px"
                    value={username}
                    border={theme.colors.grays[200]}
                    background={theme.colors.grays[100]}
                    onChange={handleChangeUsername}
                  />
                </Box>

                <Box display="flex" flexDirection="column" marginTop="24px">
                  <label htmlFor="password" style={{ marginBottom: 8 }}>
                    <Text
                      fontSize={theme.typograpgy.body.size}
                      fontWeight={theme.typograpgy.body.weight}
                      textColor={theme.colors.grays[600]}
                      margin={0}
                    >
                      Password
                    </Text>
                  </label>
                  <Input
                    tabIndex={2}
                    border={theme.colors.grays[200]}
                    background={theme.colors.grays[100]}
                    id="password"
                    h="48px"
                    mw="568px"
                    type="password"
                    p="14px 16px"
                    borderRadius="6px"
                    value={password}
                    onChange={handleChangePassword}
                  />
                </Box>
              </Box>
              <Button
                tabIndex={3}
                w={"268px"}
                h={"48px"}
                borderRadius={"6px"}
                background={theme.colors.primary[500]}
                border={`1px solid ${theme.colors.primary[500]}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                cursor={"pointer"}
                onClick={handleSubmit}
                disabled={loading}
                m={`${theme.spacing[500]} auto`}
              >
                <Text
                  textColor={"white"}
                  fontSize={theme.typograpgy.text_button.size}
                  fontWeight={theme.typograpgy.text_button.weight}
                  marginBottom={"0px"}
                >
                  {loading ? "Signin in ..." : "SIGN IN"}
                </Text>
              </Button>
              {error && (
                <Box>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    textColor={theme.colors.negative[600]}
                    marginTop={"10px"}
                  >
                    Username/password mismatch
                  </Text>
                </Box>
              )}
            </Box>
          </form>
        </Grid>
      </Box>
    </Grid>
  );
};

export default TestSignin;
