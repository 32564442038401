import React from "react";

const Battery = ({ fill = "#011726" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0583 3.33342H11.6666V1.66675H8.33325V3.33342H6.94159C6.33325 3.33342 5.83325 3.83342 5.83325 4.44175V17.2168C5.83325 17.8334 6.33325 18.3334 6.94159 18.3334H13.0499C13.6666 18.3334 14.1666 17.8334 14.1666 17.2251V4.44175C14.1666 3.83342 13.6666 3.33342 13.0583 3.33342ZM9.16659 16.6668V12.0834H7.49992L10.8333 5.83342V10.4167H12.4999L9.16659 16.6668Z"
        fill={fill}
        fill-opacity="0.3"
      />
    </svg>
  );
};

export default Battery;
