/* eslint-disable  */

import React from "react";
import Box from "ReusableComponents/Box";
import Text from "ReusableComponents/Text";
import theme from "theme";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import MediaImage from "assets/MediaImage.svg";
import Pagination from "../Pagination";
import MediaLeftIcon from "assets/MediaLeftIcon.svg";
import MediaRightIcon from "assets/MediaRightIcon.svg";

import RightNav from "assets/RightNav.svg";
import LeftNav from "assets/LeftNav.svg";
const images = [
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
  {
    original: MediaImage,
    thumbnail: MediaImage,
  },
];

const Media = ({ name, isSmall }) => {
  return (
    <Box background="white">
      <Box background="white" h="auto">
        <Box
          background="white"
          display="flex"
          justifyContent="space-between"
          margin="6px 20px"
          alignItems="center"
        >
          <Box>
            <Text
              fontSize={
                isSmall
                  ? theme.typograpgy.heading_1.smallSize
                  : theme.typograpgy.heading_2.size
              }
              fontWeight={isSmall ? 400 : theme.typograpgy.heading_2.weight}
              margin={0}
              marginTop={isSmall ? 0 : "32px"}
            >
              {name}
            </Text>
            <Text
              fontSize={theme.typograpgy.body.size}
              fontWeight={theme.typograpgy.body.weight}
              textColor={theme.colors.grays[600]}
              margin={0}
            >
              Showing 1-12 of 34
            </Text>
          </Box>
          {/* filters */}
          {/* <Box>
            <Box display="flex">
              <Dropdown
                background="#FFFFFF"
                main={"Latest"}
                margin={"58px 98px"}
                secondary={"Sort:"}
                isSecondaryFirst
                border={`1px solid ${theme.colors.grays[900]}`}
                borderRadius="6px"
                w="180px"
                width="240px"
                h="48px"
                display="flex"
                margin="5px 20px"
                justifyContent="space-between"
                alignItems="center"
                hoverBackground="white"
                options={[
                  {
                    id: 1,
                    option: "option1",
                  },
                  {
                    id: 2,
                    option: "option2",
                  },
                  {
                    id: 3,
                    option: "option3",
                  },
                ]}
              />
            </Box>
          </Box> */}
        </Box>
      </Box>
      {/* image */}
      <Box marginTop="24px" margin={isSmall ? "12px" : 0}>
        <ImageGallery
          items={images}
          renderLeftNav={(onClick) => (
            <img
              src={LeftNav}
              alt="left"
              onClick={onClick}
              style={{
                position: "absolute",
                cursor: "pointer",
                top: "50%",
                left: "5%",
                zIndex: 2,
              }}
            />
          )}
          renderRightNav={(onClick) => (
            <img
              src={RightNav}
              alt="right"
              onClick={onClick}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "50%",
                right: "5%",
                zIndex: 2,
              }}
            />
          )}
          showFullscreenButton={false}
          showPlayButton={false}
        />
      </Box>
      <Pagination padding={"20px 0px"} />
    </Box>
  );
};

export default Media;
