import React from "react";
import { Grid, Container } from "@material-ui/core";
import Logo from "../../assets/Logo.svg";
import Box from "../../ReusableComponents/Box/";
import theme from "../../theme";
import Text from "../../ReusableComponents/Text";

const InProgress = () => {
  return (
    <Container fluid style={{ marginTop: "25vh" }}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="column" textAlign="center">
            <Box marginBottom="20px" margin="0 auto">
              <img
                src={Logo}
                height="100px"
                alt="logo"
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Text
              fontSize={theme.typograpgy.heading_callout.size}
              fontWeight={theme.typograpgy.heading_callout.weight}
              margin={0}
              textAlign="center"
            >
              Home of your current and future products
            </Text>
            <Text
              fontSize={theme.typograpgy.heading_callout.size}
              fontWeight={theme.typograpgy.heading_callout.weight}
              textColor={theme.colors.primary[500]}
              margin={0}
            >
              On the way!
            </Text>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default InProgress;
