import styled from "styled-components";

export const AppStyled = styled.div`
  background: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin = 0 }) => margin};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
`;
