import * as actionTypes from "store/product/actionTypes";
import {
  displayFeatures,
  connectivityFeatures,
  cameraFeatures,
  generalFeatures,
  performance,
  reviews,
  storage,
} from "Pages/Landing/constants";
const initialState = {
  compareProducts: [],
  displayFeatures,
  connectivityFeatures,
  cameraFeatures,
  generalFeatures,
  performance,
  reviews,
  storage,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPARE_PRODUCTS: {
      const { compareProducts } = state;
      const { payload } = action;
      const isPresent = compareProducts.find(
        (item) => item.name === payload.name
      );
      return {
        ...state,
        compareProducts: !isPresent
          ? [...compareProducts, action.payload]
          : compareProducts,
      };
    }

    case actionTypes.CHECK_PRODUCT_DISPLAY: {
      const { displayFeatures } = state;
      const { payload } = action;
      return {
        ...state,
        displayFeatures: displayFeatures.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }
    case actionTypes.REMOVE_FROM_COMPARE_PRODUCTS: {
      const { compareProducts } = state;
      const { payload } = action;

      return {
        ...state,
        compareProducts: compareProducts.filter(
          (item) => item.name !== payload
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_CONNECTIVITY: {
      const { connectivityFeatures } = state;
      const { payload } = action;
      return {
        ...state,
        connectivityFeatures: connectivityFeatures.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }
    case actionTypes.CHECK_PRODUCT_CAMERA: {
      const { cameraFeatures } = state;
      const { payload } = action;
      return {
        ...state,
        cameraFeatures: cameraFeatures.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_CAMERA_ALL: {
      const { cameraFeatures } = state;

      return {
        ...state,
        cameraFeatures: cameraFeatures.map((item) => ({
          ...item,
          checked: !item.checked,
        })),
      };
    }

    case actionTypes.CHECK_PRODUCT_GENERAL: {
      const { generalFeatures } = state;
      const { payload } = action;
      return {
        ...state,
        generalFeatures: generalFeatures.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_STORAGE: {
      const { storage } = state;
      const { payload } = action;
      return {
        ...state,
        storage: storage.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_PERFORMANCE: {
      const { performance } = state;
      const { payload } = action;
      return {
        ...state,
        performance: performance.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_REVIEWS: {
      const { reviews } = state;
      const { payload } = action;
      return {
        ...state,
        reviews: reviews.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_GENERAL_ALL: {
      const { generalFeatures } = state;

      return {
        ...state,
        generalFeatures: generalFeatures.map((item) => ({
          ...item,
          checked: !item.checked,
        })),
      };
    }

    default: {
      return state;
    }
  }
};
