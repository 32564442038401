import React from "react";
import { ButtonStyled } from "./styles";

const Button = ({ children, ...props }) => {
  return (
    <ButtonStyled {...props} data-testid="button">
      {children}
    </ButtonStyled>
  );
};

export default Button;
