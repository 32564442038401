import styled from "styled-components";

export const SidebarStyled = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  border: ${({ border }) => border};
  margin: ${({ m }) => m};
  font-weight: ${({ fontWeight }) => fontWeight};
  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const SidebarImageStyled = styled.div`
  width: ${({ width }) => width};
`;

export const SidebarLogoDivStyled = styled.div`
  margin: ${({ margin }) => margin};
`;
