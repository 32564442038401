import styled from "styled-components";
import theme from "../../theme";

export const SearchStyled = styled.input`
  border-radius: ${({ borderRadius }) => borderRadius};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ m }) => m};
  font-weight: ${({ fontWeight }) => fontWeight};
  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};

  ${({ placeholderColor }) =>
    placeholderColor &&
    ` ::placeholder {
    color: ${placeholderColor};
  `}
`;

export const SearchStyledDiv = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border }) => border};
  display: ${({ display }) => display};
  margin: ${({ margin }) => margin};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  &:focus {
    border: 1px solid ${theme.colors.grays[400]};
  }
  &:focus {
    border: 1px solid ${theme.colors.primary[500]};
  }
`;

export const SearchIconStyled = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius};
  display: ${({ display }) => display};
  margin: ${({ m }) => m};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};

  background-color: ${({ background = "white" }) => background};
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ p }) => p};
  color: ${({ textColor }) => textColor};
  height: ${({ h }) => h};
  width: ${({ w }) => w};
  border: ${({ border = "none" }) => border};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter};
  cursor: ${({ cursor }) => cursor};
  line-height: ${({ lineHeight }) => lineHeight};
`;
