import React, { Component } from "react";

const googleAdId = "ca-pub-7456640261721447";

class GAdd extends Component {
  componentDidMount() {
    console.log(this.props);
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <>
        <ins
          // className="adsbygoogle"
          style={{
            display: "inline-block",
            width: "728px",
            height: "90px",
          }}
          data-ad-client={googleAdId}
          data-ad-slot={this.props.slot}
          data-ad-format="auto"
          data-adtest="on"
          data-full-width-responsive="true"
        ></ins>
      </>
    );
  }
}

export default GAdd;
