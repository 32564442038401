/* eslint-disable */
import { message, Spin } from "antd";
import { v4 as uuid } from "uuid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import AddReview1 from "../../Pages/Product/Reviews/AddReview1";
import theme from "../../theme";
import Compressor from "compressorjs";
import axios, { CancelToken, isCancel } from "axios";
import { useSelector, useDispatch } from "react-redux";
import "react-dropzone-uploader/dist/styles.css";
import Text from "../Text";
import Box from "../Box";
import Cancel from "../../assets/cancel.png";
import { thumbnails } from "../../store/media/actions";
import REACT_APP_USER_API_DOMAIN from "../../environments";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: theme.colors.primary[800],
  // borderStyle: "dotted",

  outline: "none",
  transition: "border .24s ease-in-out",
  //   background: theme.colors.primary[200],
};

const activeStyle = {
  borderColor: theme.colors.primary[800],
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZone = ({ prev, data, getDragActive }) => {
  const dispatch = useDispatch();

  const thumbnailsArray = useSelector(({ media }) => media.thumbnails);
  console.log(
    "🚀 ~ file: index.js ~ line 48 ~ DropZone ~ thumbnailsArray",
    thumbnailsArray
  );

  // let source = axios.CancelToken.source();
  const imgRef = useRef();
  const cancelUpload = useRef();
  const token = useSelector(({ auth }) => auth.token);

  const [showSpinner, setShowSpinner] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [loading, setLoading] = useState(false);
  const [finalImageArr, setFinalImageArr] = useState([]);
  const [files, setFiles] = useState([]);

  const cancelFileUpload = () => {
    setCancel(true);
    setShowSpinner(false);
  };

  const submitReview = async () => {
    setLoading(true);
    // thumbnailsArray.map((item) => {
    //   const { imageObj } = item;

    //   const formData = new FormData();
    //   // // The third parameter is required for server
    //   formData.append("file", imageObj, imageObj.name);

    //   let img = new Image();
    //   // set the onload *before* setting the img 'src'

    //   img.src = window.URL.createObjectURL(imageObj);
    //   // setImage(image);
    //   // dispatch(thumbnails([...thumbnailsArray, ]));
    //   console.log(img);
    //   console.log(formData);
    //   // return { ...item, newImage: img };
    //   setFinalImageArr([...finalImageArr, img]);

    new Compressor(files[0], {
      quality: 0.6,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        const formData = new FormData();

        // The third parameter is required for server
        formData.append("file", result, result.name);

        let img = new Image();
        // set the onload *before* setting the img 'src'

        img.src = window.URL.createObjectURL(result);
        // setImage(image);
        // dispatch(thumbnails([...thumbnailsArray, ]));
        console.log(img);
        // return { ...item, newImage: img };
        // setFinalImageArr([...finalImageArr, img]);

        // setShowSpinner(true);

        //Send the compressed image file to server with XMLHttpRequest.

        axios
          .post(
            `http://${REACT_APP_USER_API_DOMAIN}:4000/api/v1/user/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ` + token,
              },
            }
          )
          .then(() => {
            if (cancel) message.error(`Upload Failed, Image Upload cancel`, 5);
            else {
              // message.success("Upload Success", 5);
              setShowSpinner(false);
            }
          })
          .catch((err) => {
            if (cancel) message.error(`Upload Failed, Image Upload cancel`, 5);
            console.log("upload error", err);
            setShowSpinner(false);
            message.error(`Upload Failed ${err}`, 5);
          });
      },
      error(err) {
        message.error(`Upload Failed. Media should be of type JPEG ${err}`, 5);
      },
    });

    // });

    // new Compressor(acceptedFiles[0], {
    //   quality: 0.6,

    //   // The compression process is asynchronous,
    //   // which means you have to access the `result` in the `success` hook function.
    //   success(result) {
    //     console.log(
    //       "🚀 ~ file: index.js ~ line 120 ~ success ~ result",
    //       result
    //     );
    //     const formData = new FormData();

    //     // The third parameter is required for server
    //     formData.append("file", result, result.name);

    //     // setShowSpinner(true);
    //     //Send the compressed image file to server with XMLHttpRequest.
    //     // axios
    //     //   .post("http://localhost:4000/api/v1/user/upload", formData, {
    //     //     headers: {
    //     //       "Content-Type": "multipart/form-data",
    //     //       Authorization: `Bearer ` + token,
    //     //     },
    //     //   })
    //     //   .then(() => {
    //     //     if (cancel)
    //     //       message.error(`Upload Failed, Image Upload cancel`, 5);
    //     //     // console.log("Upload success");
    //     //     // message.success("Upload Success", 1);
    //     //     setShowSpinner(false);
    //     //   })
    //     //   .catch((err) => {
    //     //     if (cancel)
    //     //       message.error(`Upload Failed, Image Upload cancel`, 5);
    //     //     console.log("upload error", err);
    //     //     setShowSpinner(false);
    //     //     message.error(`Upload Failed ${err}`, 1);
    //     //   });
    //   },
    //   error(err) {
    //     message.error(`Upload Failed ${err.message}`, 1);
    //   },
    // });
  };

  const showAlert = (name) => message.warning(name, 2);

  const onDrop = (acceptedFiles) => {
    console.log("files", acceptedFiles);
    debugger;
    setFiles(acceptedFiles);

    if (acceptedFiles.length > 6) {
      const msg = "Cannot upload more than 6 files";
      showAlert(msg);
      return;
    }

    const newFilesArray = acceptedFiles.map((file) => {
      const id = uuid().slice(0, 8);

      let img = new Image();
      const { name } = file;

      img.src = window.URL.createObjectURL(file);

      return {
        id,
        name,
        image: img.src,
        imageObj: file,
      };
    });

    const isAlreadyPresent = thumbnailsArray.find(
      (item) => item.name === newFilesArray[0].name
    );
    if (isAlreadyPresent) {
      const msg = `File with name ${newFilesArray[0].name} already present! `;
      showAlert(msg);
      return;
    }

    dispatch(thumbnails([...thumbnailsArray, ...newFilesArray]));

    //dispatch(thumbnails([...thumbnailsArray, newFilesArray]));

    // let img = new Image();
    // // set the onload *before* setting the img 'src'

    // img.src = window.URL.createObjectURL(acceptedFiles[0]);

    // dispatch(
    //   thumbnails([
    //     ...thumbnailsArray,
    //     { id: thumbnailsArray.length, image: img.src },
    //   ])
    // );

    // setFiles(newFilesArray);
    // console.log("ref", imgRef.current);
    // new Compressor(acceptedFiles[0], {
    //   quality: 0.6,

    //   // The compression process is asynchronous,
    //   // which means you have to access the `result` in the `success` hook function.
    //   success(result) {
    //     console.log("this is the result", result);
    //     const formData = new FormData();

    //     // The third parameter is required for server
    //     formData.append("file", result, result.name);

    //     let img = new Image();
    //     // set the onload *before* setting the img 'src'

    //     img.src = window.URL.createObjectURL(result);
    //     setImage(img);

    //     setShowSpinner(true);

    //     const options = {
    //       // onUploadProgress: (progressEvent) => {
    //       //   const { loaded, total } = progressEvent;
    //       //   console.log(
    //       //     "🚀 ~ file: index.js ~ line 88 ~ success ~ loaded, total",
    //       //     loaded,
    //       //     total
    //       //   );
    //       //   // let percentage = Math.floor((loaded * 100) / total);
    //       //   // console.log(`${loaded}kb of ${total}kb | ${percentage}%`);
    //       //   // if (percentage < 100) {
    //       //   //   setPercent(percentage);
    //       //   // }
    //       // },
    //     };
    //     //Send the compressed image file to server with XMLHttpRequest.

    //     axios
    //       .post(
    //         "http://localhost:4000/api/v1/user/upload",
    //         formData,
    //         {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //             Authorization: `Bearer ` + token,
    //           },
    //         },
    //         options
    //       )
    //       .then(() => {
    //         if (cancel) message.error(`Upload Failed, Image Upload cancel`, 5);
    //         else {
    //           // message.success("Upload Success", 5);
    //           setShowSpinner(false);
    //         }
    //       })
    //       .catch((err) => {
    //         if (cancel) message.error(`Upload Failed, Image Upload cancel`, 5);
    //         console.log("upload error", err);
    //         setShowSpinner(false);
    //         message.error(`Upload Failed ${err}`, 5);
    //       });
    //   },
    //   error(err) {
    //     message.error(`Upload Failed. Media should be of type JPEG`, 5);
    //   },
    // });
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".jpg, .jpeg",
    noClick: true,
    onDrop,
  });

  const showOverlay = () => (
    <div
      id="overlay"
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        position: "absolute",

        height: "150vh",
        width: "99vw",
        opacity: 0.4,
        top: "-200px",
      }}
    >
      <Box position="absolute" left="46%" top="8%">
        <Text textColor={theme.colors.primary[800]} textDecoration="underline">
          Drop your file here to upload
        </Text>
      </Box>
      {showSpinner && <Spin />}
    </div>
  );

  const showSpinOverlay = () => (
    <>
      <div
        id="overlay"
        style={{
          background: "rgba(0, 0, 0, 0.3)",
          position: "absolute",

          height: "150vh",
          width: "99vw",
          opacity: 0.4,
          top: "-200px",
        }}
      />

      <div
        style={{
          top: "1vh",
          opacity: 1,
          position: "absolute",
          background: "white",
          padding: 20,
          borderRadius: 6,
        }}
      >
        <Box display="flex" justifyContent="center" flexDirection="column">
          {/* <img
            //src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.tZq4FbHI-2VuBSGkHjfyfAHaHa%26pid%3DApi&f=1"
            src={image}
            alt="random"
            height={150}
            style={{ marginBottom: 10 }}
          /> */}
          <Spin tip="Uploading..." size="large" />
        </Box>
        <Box>
          <img
            src={Cancel}
            onClick={cancelFileUpload}
            al="cancel"
            height={20}
            style={{
              position: "absolute",
              top: -10,
              right: -10,
              cursor: "pointer",
            }}
          />
        </Box>
      </div>
    </>
  );

  useEffect(() => {
    if (isDragAccept) setShowSpinner(true);
  }, [isDragAccept]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container" style={{ position: "relative" }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />

        <AddReview1 prev={prev} data={data} loading={showSpinner} />
        {isDragActive && showOverlay()}
        {showSpinner && showSpinOverlay()}
      </div>
    </div>
  );
};

export default DropZone;
