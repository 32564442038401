import axios from "axios";

const portSignIn = process.env.REACT_APP_USER_SIGNIN;
const portProducts = process.env.REACT_APP_USER_API_PORT;
const baseUrl = process.env.REACT_APP_USER_API_DOMAIN_1 || "localhost";

const URLS = {
  SIGN_IN: "/api/v1/user/login",
  PRODUCTS: "/api/v1/products/toprated/37",
  SEARCH: (name) => `/api/v1/products/search/name/?name=${name}`,
  GET_QNA: (id) => `/api/v1/products/ques/${id}`,
  GET_PRODUCT: (id) => `/api/v1/products/info/${id}`,
  GET_REVIEWS: (id) => `/api/v1/products/reviews/${id}`,
  DETAILED_REVIEW: (id) => `/api/v1/products/reviewdetails/${id}`,
  GET_COMMENTS: (id) => `/api/v1/products/reviews/comments/${id}`,
};

const signIn = async (signInData) => {
  const apiPath = `http://${baseUrl}:${portSignIn}`;
  const fullUrlPath = apiPath + URLS.SIGN_IN;

  try {
    const { data } = await axios.post(fullUrlPath, signInData);
    return data;
  } catch (err) {
    return err;
  }
};

const getProducts = async () => {
  const apiPath = `http://${baseUrl}:${portProducts}`;
  const fullUrlPath = apiPath + URLS.PRODUCTS;

  try {
    const { data } = await axios.get(fullUrlPath);
    return data;
  } catch (err) {
    return err;
  }
};

const searchProducts = async (name) => {
  const apiPath = `http://${baseUrl}:${portProducts}`;
  const fullUrlPath = apiPath + URLS.SEARCH(name);

  try {
    const { data } = await axios.get(fullUrlPath);
    return data;
  } catch (err) {
    return err;
  }
};

const getDeailedReview = async (id) => {
  const apiPath = `http://${baseUrl}:${portProducts}`;
  const fullUrlPath = apiPath + URLS.DETAILED_REVIEW(id);

  try {
    const { data } = await axios.get(fullUrlPath);
    return data;
  } catch (err) {
    return err;
  }
};

const getComments = async (id) => {
  const apiPath = `http://${baseUrl}:${portProducts}`;
  const fullUrlPath = apiPath + URLS.GET_COMMENTS(id);

  try {
    const { data } = await axios.get(fullUrlPath);
    return data;
  } catch (err) {
    return err;
  }
};

const getQna = async (id) => {
  try {
    const apiPath = `http://${baseUrl}:${portProducts}`;
    const { data } = await axios.get(apiPath + URLS.GET_QNA(id));
    return data;
  } catch (err) {
    return err;
  }
};

const getReviews = async (id) => {
  try {
    const apiPath = `http://${baseUrl}:${portProducts}`;

    const { data } = await axios.get(apiPath + URLS.GET_REVIEWS(id));
    return data;
  } catch (err) {
    return err;
  }
};

const getProduct = async (id) => {
  try {
    const apiPath = `http://${baseUrl}:${portProducts}`;
    const { data } = await axios.get(apiPath + URLS.GET_PRODUCT(id));
    return data;
  } catch (err) {
    return err;
  }
};

export const apiClient = {
  signIn,
  getProducts,
  searchProducts,
  getProduct,
  getQna,
  getReviews,
  getDeailedReview,
  getComments,
};
