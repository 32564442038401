/* eslint-disable */
/* eslint-disable array-callback-return */

import React from "react";
import Box from "../../../ReusableComponents/Box";
import Text from "../../../ReusableComponents/Text";
import Battery from "../../../assets/Battery.svg";
import PhoneSmall from "../../../assets/PhoneSmall.svg";
import AppGrid from "../../../assets/AppGrid.svg";
import Eye from "../../../assets/Eye.svg";
import Speed from "../../../assets/Speed.svg";
import BuildQuality from "../../../assets/BuildQuality.svg";
import theme from "../../../theme";
import RatingBar from "../../../ReusableComponents/RatingBar";
import axios from "axios";
import { Grid } from "@material-ui/core";
import StarReview from "../../../assets/StarReview.svg";
import FlagReview from "../../../assets/FlagReview.svg";
import BigStar from "../../../assets/BigStar.svg";
import FullWhiteStar from "../../../assets/FullWhiteStar.svg";
import FullBlueStar from "../../../assets/FullBlueStar.svg";
import FullReview from "../Reviews/Comments/FullReview";
import FullReviewTitle from "../Reviews/Comments/FullReviewTitle";

const TopReview = ({ isFullReview, isSmall }) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      try {
        const port = process.env.REACT_APP_PRODUCT_RATING_API_PORT || 4000;
        const baseUrl = process.env.REACT_APP_USER_API_DOMAIN || "localhost";
        const result = await axios.get(
          `http://${baseUrl}:${port}/api/v1/products/ratings/P0151729556`
        );
        if (result) {
          console.log(result?.data);
          setData(result?.data?.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    if (data) {
      Object.keys(data).map((key) => {
        if (
          key.includes("Rating") &&
          !key.includes("Icon") &&
          !key.includes("ID")
        ) {
          const keyname = key.replace(/Rating/g, "");
        }
      });
    }
  }, [data]);

  return (
    <Box
      marginTop={isFullReview ? "6px" : "24px"}
      background={"white"}
      padding="20px"
    >
      <Grid container spacing={2}>
        {isFullReview && (
          <Grid item xs={12}>
            <FullReviewTitle />
          </Grid>
        )}

        <Grid item xs={isSmall ? 12 : 3} md={4}>
          <Box
            display="flex"
            position="relative"
            h="108px"
            borderRadius="6px"
            background="linear-gradient(97.24deg, #0598FA 0%, rgba(5, 152, 250, 0.4) 100%)"
          >
            {/* star image */}
            <Box position="absolute" left="46px">
              <img src={BigStar} />
            </Box>
            <Box
              display="flex"
              flexDirection={isFullReview ? "column" : "row"}
              alignItems="center"
              justifyContent="center"
              background={theme.colors.primary[500]}
            >
              <Box
                borderRadius="6px"
                borderRadiusTopRight="none"
                borderRadiusBottomRight="none"
                display="flex"
                alignItems="baseline"
                w="100px"
                justifyContent="center"
                background={theme.colors.primary[500]}
              >
                <Text
                  fontSize={theme.typograpgy.body_roboto.size32}
                  fontWeight={theme.typograpgy.body_roboto.weight500}
                  textColor={theme.colors.white[900]}
                  margin={0}
                >
                  {data.OverallRatingID}
                </Text>
                <Text
                  fontSize={"14px"}
                  fontWeight={theme.typograpgy.body.weight}
                  textColor={"white"}
                  margin={0}
                >
                  /5
                </Text>
              </Box>
            </Box>
            {!isFullReview ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent=" center"
                alignItems=" flex-end"
                marginLeft="auto"
                marginRight="10px"
              >
                <Text
                  fontSize={700}
                  fontWeight={"14px"}
                  textColor={theme.colors.white[900]}
                  margin={0}
                  marginBottom="6px"
                >
                  Base
                </Text>
                <Text
                  fontSize={500}
                  fontWeight={"14px"}
                  textColor={theme.colors.white[900]}
                  margin={0}
                  marginBottom="6px"
                >
                  SEGMENT AVG.
                </Text>
                <Box display="flex">
                  <Box display="flex" alignItems="center">
                    <img src={StarReview} style={{ marginRight: 5 }} />
                    <Text
                      fontSize={theme.typograpgy.body.size}
                      fontWeight={theme.typograpgy.body.weight}
                      textColor={theme.colors.white[900]}
                      margin={0}
                      marginRight="10px"
                    >
                      3.9
                    </Text>
                    <img src={FlagReview} style={{ marginRight: 5 }} />
                    <Text
                      fontSize={theme.typograpgy.body.size}
                      fontWeight={theme.typograpgy.body.weight}
                      textColor={theme.colors.white[900]}
                      margin={0}
                      marginRight="5px"
                    >
                      405 reviews
                    </Text>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent=" center"
                alignItems=" center"
                marginLeft=" auto"
                marginRight=" 20px"
              >
                <img alt="img" src={FullWhiteStar} height={"20px"} />

                <img alt="img" src={FullWhiteStar} height={"20px"} />
                <img alt="img" src={FullWhiteStar} height={"20px"} />

                <img alt="img" src={FullWhiteStar} height={"20px"} />
                <img alt="img" src={FullBlueStar} height={"20px"} />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          {/* rows */}

          <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <img alt="img" src={Battery} />
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="5px"
                  >
                    Battery life
                  </Text>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="auto"
                  >
                    4.4
                  </Text>
                </Box>
                <RatingBar
                  rating={4}
                  //   w"auto"
                  width="auto"
                  marginTop="8px"
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={12} md={4}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <img alt="img" src={Speed} />
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="5px"
                  >
                    Performance
                  </Text>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="auto"
                  >
                    3.9
                  </Text>
                </Box>
                <RatingBar rating={3} width="auto" marginTop="8px" />
              </Box>
            </Grid>

            <Grid item xs={6} sm={12} md={4}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-around">
                  <img alt="img" src={BuildQuality} />
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="5px"
                  >
                    Build Quality
                  </Text>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="auto"
                  >
                    4.1
                  </Text>
                </Box>
                <RatingBar rating={4} width="auto" marginTop="8px" />
              </Box>
            </Grid>

            <Grid item xs={6} sm={12} md={4}>
              <Box display="flex" flexDirection="column" margin="8px 0px">
                <Box display="flex" justifyContent="space-around">
                  <img alt="img" src={PhoneSmall} />
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="5px"
                  >
                    Hardware
                  </Text>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="auto"
                  >
                    4.4
                  </Text>
                </Box>
                <RatingBar rating={4} width="auto" marginTop="8px" />
              </Box>
            </Grid>

            <Grid item xs={6} sm={12} md={4}>
              <Box display="flex" flexDirection="column" margin="8px 0px">
                <Box display="flex" justifyContent="space-around">
                  <img alt="img" src={Eye} />
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="5px"
                  >
                    Appearance
                  </Text>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="auto"
                  >
                    4.3
                  </Text>
                </Box>
                <RatingBar rating={4} width="auto" marginTop="8px" />
              </Box>
            </Grid>

            <Grid item xs={6} sm={12} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                margin={`${theme.spacing[300]} 0px`}
              >
                <Box display="flex" justifyContent="space-around">
                  <img alt="img" src={AppGrid} />
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="5px"
                  >
                    Software & OS
                  </Text>
                  <Text
                    fontSize={theme.typograpgy.body.size}
                    fontWeight={theme.typograpgy.body.weight}
                    margin="0px"
                    marginLeft="auto"
                  >
                    1.8
                  </Text>
                </Box>
                <RatingBar rating={1} width={"auto"} marginTop="8px" />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {isFullReview && <FullReview isSmall={isSmall} />}
      </Grid>
    </Box>
  );
};

export default TopReview;
